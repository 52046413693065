import React,{ useEffect,}  from 'react';
import { Form } from 'react-final-form';
import { makeStyles  } from '@material-ui/core/styles';

import {List, Datagrid,DateField,TextField,NumberField,useListContext,TextInput,NullableBooleanInput } from 'react-admin';
import { Box, Button, InputAdornment  } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import ContentFilter from "@material-ui/icons/FilterList";
import { DateInput, TimeInput, DateTimeInput } from '../DatePicker';
import {ButtonAssignNSFP} from './ButtonAssignNSFP'
import { ToolbarSelected } from './ToolbarSelected';
import {ExporterEfakturCSV} from '../CustomComponents/ExporterEfakturCSV'
import {ToopToolbarFaktur} from './ToopToolbarFaktur'

import moment from 'moment'


const DateFilterForm = ({ open }) => {
    const {
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter,
    } = useListContext();


    const onSubmit = values => {
        setFilters(values);

    };

    const today = moment().format("YYYY-MM-DD")
    
    const lastWeek = moment().subtract(7,'days').format("YYYY-MM-DD")

    if(filterValues.date_from == null){

        filterValues.date_from = lastWeek;
        filterValues.date_until = today
        setFilters({date_from : lastWeek, date_until:today})

    }
    //console.log(filterValues)
    return (
        <Form onSubmit={onSubmit} initialValues={filterValues}>
            {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
                <Box display="flex" alignItems="center" mb={2} ml={2}>
                    <Box component="span" mr={2}>
                        <DateInput source="date_from"  label="Dari Tanggal" defaultValue={filterValues.date_from} options={{ format: 'dd/MM/yyyy' }}/>
                    </Box>
                    <Box component="span" mr={2} >
                        <h2>-</h2>
                    </Box>
                    <Box component="span" mr={2}>
                        <DateInput source="date_until"  label="Sampai Tanggal"  defaultValue={filterValues.date_until} options={{ format: 'dd/MM/yyyy' }}/>
                    </Box>
                    <Box component="span" mr={2} >
                        <Button variant="outlined" color="primary" type="submit">
                            Filter
                        </Button>
                    </Box>
                </Box>
            </form>
            )}
        </Form>
    );
};

const DateFilterButton = () => {
    const { showFilter } = useListContext();
    return null
};

const DateFilter = props => {
    return props.context === "button" ? (
        <DateFilterButton {...props} />
      ) : (
        <DateFilterForm {...props} />
      );
}



const useStyles = makeStyles({

    'layout' :{
        backgroundColor : "#ffffff"
    },

    list_scroll : {

        
        '&& .MuiPaper-root > table':{
            height: '625px', 
            display: 'block',
            overflowY:"scroll",
            backgroundColor : "#e8e8e8 !important"
        },

        '&&& [class*="RaBulkActionsToolbar-toolbar-"]':{
            position : "sticky",
            top : 0
        },

        '&& [class*="RaList-bulkActionsDisplayed-"] > table > thead > tr > th':{
            top : 0,
            position : "sticky"
        }
    },
    tbl_alamat: { 
        width : 250
    },

    primaryColorText : {
        color : "#1357FF"
    }
})



export const FakturPenjualanList = (props) =>{

    const classes = useStyles();

    const numberFormatOptions = {
        minimumFractionDigits : 2,
        maximumFractionDigits : 2
    }
    const numberFormat = new Intl.NumberFormat("en-id", numberFormatOptions)

    const TextfieldPrimaryColor = props =>{
        return <TextField  className={classes.primaryColorText} source="transaction_no" label="No. Transaksi."  {...props} />
    }

    const TextfieldUploaded = props =>{

        let uploaded = props.record.upload

        if(uploaded == null || uploaded === 0){
            uploaded = ""
        }
        else if (uploaded === 1){
            uploaded = "✔"
        }

        return <span style={{display : "block", width : "100%", textAlign : "center", color : "#00a152", fontSize : "20px"}}>{uploaded}</span>
    }
    return (
        <List {...props} 
            title="Faktur Penjualan" 
            sort={{field : "transaction_no", order: 'ASC'}} 
            filters={<DateFilter/>} 
            bulkActionButtons={<ToolbarSelected />} 
            pagination={false}
            className={classes.list_scroll}
            actions={<ToopToolbarFaktur/>} 
            

        >
            <Datagrid rowClick="show" >
                <DateField source="tanggal_transaksi" locales="fr" label="Tgl Transaksi" />
                <TextfieldPrimaryColor source="transaction_no" label="No. Transaksi"/>
                <TextfieldUploaded source="upload" label="Selesai" />
                <TextField source="nsfp" label="NSFP" />
                <TextField source="npwp" label="NPWP" sortable={false}/>
                <TextField source="perusahaan" label="Perusahaan" />
                <TextField source="alamat_billing" label="Alamat" headerClassName={classes.tbl_alamat} sortable={false}/>
                <NumberField  source="dpp" label="DPP" options={numberFormatOptions} sortable={false} />
                <NumberField  source="ppn" label="PPN" options={numberFormatOptions} sortable={false}/>

            </Datagrid>
        </List>
    )
}