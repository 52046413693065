import React, { useEffect } from 'react'
import { fetchUtils,Admin, Resource  } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import inMemoryJWT from './components/Auth/inMemoryJwt'
import AuthProvider from './components/Auth/AuthProvider'
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MainLayout from './components/Layout/MainLayout'
import { createMuiTheme } from '@material-ui/core/styles';


//FAKTUR PENJUALAN
import { FakturPenjualanList } from './components/FakturPenjualan/fakturPenjualanList';

//NSFP
import {NSFPList} from './components/NSFP/nsfpList';
import {NSFPEdit} from './components/NSFP/nsfpEdit';
import {NSFPCreate} from './components/NSFP/nsfpCreate'

const theme = createMuiTheme({

  overrides : {
    MuiAppBar : {
      root : {
        backgroundColor : '#64b5f6 !important',
        height : 50
      }
    },
    MuiToolbar : {
      regular : {
        marginBottom : "45px"
      }
    },
  },
  sidebar: {
      width: 200, // The default value is 240
      closedWidth: 50, // The default value is 55
  },
});

const apiURL = process.env.REACT_APP_API_ADDRESS

const httpClient = (url,options = {}) => {
  if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = inMemoryJWT.getToken();

  if(token){
      options.headers.set('Authorization', `Bearer ${token}`);
      return fetchUtils.fetchJson(url, options);
  }else{
      inMemoryJWT.setRefreshTokenEndpoint(apiURL + '/refresh-token');
      return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
          if (gotFreshToken) {
              options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
          };
          return fetchUtils.fetchJson(url, options);
      });
  }
  
  
}

const dataProvider = simpleRestProvider(apiURL,httpClient);

const App = () => {

  useEffect(() => {
    document.title = "PBF Efaktur"
  })
  return (
    <div>
      <Admin theme={theme} dataProvider={dataProvider} authProvider = {AuthProvider} layout={MainLayout}>
        <Resource name="FakturPenjualan" list={FakturPenjualanList} options={{label : "Faktur Penjualan"}} icon={FormatListBulletedIcon}/>
        <Resource name="NSFP" list={NSFPList} create={NSFPCreate} edit={NSFPEdit} options={{label : "NSFP"}} icon={MoreVertIcon} />
      </Admin>
    </div>
  )
}

export default App;