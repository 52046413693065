import * as React from 'react';
import { Fragment } from 'react';
import Button from '@material-ui/core/Button';

import { useUpdateMany,useRefresh,useNotify,useUnselectAll, } from 'react-admin';
import inMemoryJWT from '../Auth/inMemoryJwt'

const apiURL = process.env.REACT_APP_API_ADDRESS

const ButtonResetUploaded = ({selectedIds}) =>{

    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const buttonClick = (selectedIds) =>{
        const idJSON = {ids : selectedIds}

        const token = inMemoryJWT.getToken()
        let url = apiURL + '/resetuploaded';

        const request = new Request(url,{
            method : 'POST',
            headers : new Headers({
                'Content-Type' : 'application/json',
                'Authorization': `Bearer ${token}`
            }),
            body : JSON.stringify(idJSON)
        })

        return fetch(request)
        .then((response) => {
            console.log("RESPONSE")
            console.log(response)
            notify("Reset Upload Berhasil")
            refresh()
        })
    }

    return(
        <div>
            <Button variant="contained" color="secondary" onClick={() => buttonClick(selectedIds)}>
                Reset Upload 
            </Button>
        </div>
    )
}

const ButtonSetUploaded = ({selectedIds}) =>{

    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();

    const buttonClick = (selectedIds) =>{
        const idJSON = {ids : selectedIds}

        const token = inMemoryJWT.getToken()
        let url = apiURL + '/setuploaded';

        const request = new Request(url,{
            method : 'POST',
            headers : new Headers({
                'Content-Type' : 'application/json',
                'Authorization': `Bearer ${token}`
            }),
            body : JSON.stringify(idJSON)
        })

        return fetch(request)
        .then((response) => {
            console.log("RESPONSE")
            console.log(response)
            notify("Set Upload Berhasil")
            refresh()
        })

    }

    return(
        <div style={{marginLeft : "100px"}}>
            <Button variant="contained" color="primary" onClick={() => buttonClick(selectedIds)}>
                Sudah Diupload 
            </Button>
        </div>
    )
}


const ButtonNSFP = ({selectedIds}) =>{
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll();
    
    const buttonClick = (selectedIds) =>{


        const idJSON = {ids : selectedIds}


        const token = inMemoryJWT.getToken()
        let url = apiURL + '/assignnsfp';

        const request = new Request(url,{
            method : 'PATCH',
            headers : new Headers({
                'Content-Type' : 'application/json',
                'Authorization': `Bearer ${token}`
            }),
            body : JSON.stringify(idJSON)
        });

        
        return fetch(request)
        .then((response) => {

            const result = response.json();
            return result
        })
        .then(function(result){

            notify(result.message)

            refresh()
        })
    }

    return(
        <div style={{marginLeft : "20px"}}>
            <Button variant="contained" color="primary" onClick={() => buttonClick(selectedIds)}>
            Tambah No. Faktur
            </Button>
        </div>
        
    )
}

export const ToolbarSelected = props => (
    <Fragment>
        <ButtonResetUploaded {...props}/>
        <ButtonSetUploaded {...props}/>
       
        {/*<ButtonNSFP {...props}/> */}
        
    </Fragment>
);