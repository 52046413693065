import React from 'react'
import { SimpleForm} from 'react-admin';

export const SimpleFormWithDefaults= ({record,save, saving,children}) => {

    const data = record
    return(
    <SimpleForm  initialValues={record} save={save} saving={saving} >
        {React.cloneElement(children,{data:data})}
    </SimpleForm>
    )
}