import React from 'react'
import {Create, Datagrid,SimpleForm,TextInput} from 'react-admin';
import {DateInput} from '../DatePicker'

export const NSFPCreate = (props) =>{

    const numberFormatOptions = {
        minimumFractionDigits : 2,
        maximumFractionDigits : 2
    }
    const numberFormat = new Intl.NumberFormat("en-id", numberFormatOptions)

    return (
        <Create {...props} title="NSFP Baru" >
            <SimpleForm>
                <TextInput source="dari_no" label="Dari No." />
                <TextInput source="sampai_no" label="Sampai No." />

                <DateInput source="tgl_terbit" defaultValue={new Date()} options={{ format: 'dd/MM/yyyy' }}/>
            </SimpleForm>
        </Create>
    )
}